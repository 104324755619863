import React, { FC, useState } from "react";
import { useMutation, useQuery, gql } from '@apollo/client';
import { styled, Dialog, CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import { useHistory } from 'react-router-dom'
import { ShippingStatusButton_order, ShippingStatusButton_UpdateShippingStatusMutation } from "__generated__/api";
import ConfirmDialog from "components/Common/ConfirmDialog";

export const ShippingStatusButtonFragment = gql`
  fragment ShippingStatusButton_order on Order {
    id

    shipping {
      id
      status
    }
  }
`

const mutation = gql`
  mutation ShippingStatusButton_UpdateShippingStatusMutation($input: UpdateShippingStatusInput!) {
    updateShippingStatus(input: $input) {
      order {
        ...ShippingStatusButton_order
      }
    }
  }
  ${ShippingStatusButtonFragment}
`

type Props = {
  order: ShippingStatusButton_order
}

const SubmitButton = styled(Button)({
  backgroundColor: "#000 !important",
  borderRadius: "50px",
  height: "55px",
  fontSize: "13px",
  fontWeight: "bold",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
  },
  width: "100%",
  maxWidth: "380px"
})

const ShippingStatusButton: FC<Props> = (props) => {
  const order = props.order
  const [open, setOpen] = useState(false)
  const [mutate] = useMutation<ShippingStatusButton_UpdateShippingStatusMutation>(mutation);
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false)

  const onYes = async () => {
    setIsloading(true)

    await mutate({
      variables: {
        input: {
          orderId: order.id
        }
      },
      onCompleted: (res) => {
        // TODO : ALERT

        setOpen(false)
        history.push("/orders");
      },
      onError: (error) => {
        // TODO : ALERT

        setOpen(false)
        setIsloading(false)
      }
    });
  }

  return (
    <>
      <ConfirmDialog
        open={open}
        doYes={onYes}
        doNo={() => { setOpen(false) }}
        message={"発送完了にしますか？"}
        yesText={"完了にする"}
        noText={"キャンセル"}
      />
      {
        order.shipping.status === "PENDING" && <>
          <SubmitButton onClick={() => { setOpen(true) }}>
            {
              isLoading
                ?
                <CircularProgress size={20} />
                :
                <span style={{ color: "#fff" }}>発送完了にする</span>
            }
          </SubmitButton>
        </>
      }
    </>
  )

}

export default ShippingStatusButton;