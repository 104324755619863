import { OperationType, GraphQLTaggedNode } from "relay-runtime";
import { fetchQuery } from "react-relay";
import { initEnvironment } from "lib/relay";

export const handleFetch = async <T extends OperationType>(
  query: GraphQLTaggedNode,
  variables: T["variables"]
) => {
  const environment = initEnvironment();
  const res = await fetchQuery<T>(environment, query, variables);
  return res.toPromise();
};
