import React, { FC, useMemo } from "react";
import { useQuery, gql } from '@apollo/client';
import { ShippingStatusLabel_order } from "__generated__/api";

export const ShippingStatusLabelFragment = gql`
  fragment ShippingStatusLabel_order on Order {
    shipping {
      id
      status
    }
  }
`

type Props = {
  order: ShippingStatusLabel_order
}

const ShippingStatusLabel: FC<Props> = (props) => {
  const { order } = props
  let statusText, backgroundColor, color;

  [statusText, backgroundColor, color] = useMemo(() => {
    switch (order.shipping.status) {
      case "PENDING":
        return ["未発送", "#D2F4FD", "#5EA3B6"];
      case "SHIPPED":
        return ["発送完了", "#EAE6FF", "#5B4DAE"];
      case "CANCELED":
        return ["キャンセル", "#F4F4F1", "#7D8F8E"];
    }
  }, [order]);

  return (
    <span
      style={{
        display: "inline-block",
        padding: "5px 10px",
        borderRadius: "16px",
        fontSize: "11px",
        fontWeight: "bold",
        backgroundColor: backgroundColor,
        color: color
      }}
    >
      {statusText}
    </span>
  )

}

export default ShippingStatusLabel;