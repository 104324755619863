import React, { FC, useMemo } from "react";
import { gql } from "@apollo/client";
import { PaymentStatusLabel_payment } from "__generated__/api";

export const PaymentStatusLabelFragment = gql`
  fragment PaymentStatusLabel_payment on OrderPayment {
    id
    status
  }
`

type Props = {
  payment: PaymentStatusLabel_payment
}

const PaymentStatusLabel: FC<Props> = (props) => {
  const payment = props.payment
  let statusText, backgroundColor, color;

  [statusText, backgroundColor, color] = useMemo(() => {
    switch (payment.status) {
      case "COMPLETED":
        return ["決済完了", "#D2F4FD", "#5EA3B6"];
      case "PENDING":
        return ["決済待ち", "#EAE6FF", "#5B4DAE"];
      case "REPAIED":
        return ["返金済み", "#FDF5D6", "#FFA839"];
      case "CANCELED":
        return ["キャンセル", "#F4F6F7", "#B9C2CA"];
      case "FAILED":
        return ["決済失敗", "#FDF2F2", "#D85E5E"];
    }
  }, [payment]);

  return (
    <span
      style={{
        display: "inline-block",
        padding: "5px 10px",
        borderRadius: "16px",
        fontSize: "11px",
        fontWeight: "bold",
        backgroundColor: backgroundColor,
        color: color
      }}
    >
      {statusText}
    </span>
  )

}

export default PaymentStatusLabel;