import { Environment, Network, RecordSource, Store } from "relay-runtime";

let relayEnvironment;

function createEnvironment(onUnauthorize?: () => void) {
  function fetchQuery(operation, variables) {
    // TODO
    return fetch(`${process.env.REACT_APP_API_URL}/api/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (!json.errors || json.errors.length === 0) {
          return json;
        }

        console.log(json.errors);

        const authorizeError = json.errors.find(
          (e) => e.extensions?.code === "UNAUTHORIZED"
        );
        if (authorizeError) {
          onUnauthorize && onUnauthorize();
        }

        return json;
      });
  }

  return new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });
}

export function initEnvironment(onUnauthorize?: () => void) {
  const environment = relayEnvironment ?? createEnvironment(onUnauthorize);

  if (!relayEnvironment) {
    relayEnvironment = environment;
  }

  return relayEnvironment;
}
