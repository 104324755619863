import React, { FC } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { PasswordForm_CreatePasswordMutation, PasswordMethod } from "__relay_artifacts__/PasswordForm_CreatePasswordMutation.graphql";
import { styled, Button, CircularProgress } from "@mui/material";
import StyledErrorMessage from "components/Common/StyledErrorMessage";
import * as Yup from "yup";

const emailSchema = Yup.object({
  email: Yup.string().required("メールアドレスを入力してください").email("正しいメールアドレスを入力してください"),
});

const numberSchema = Yup.object({
  number: Yup.string().required("電話番号を入力してください")
});

const mutation = graphql`
  mutation PasswordForm_CreatePasswordMutation($input: CreatePasswordInput!) {
    createPassword(input: $input) {
      password {
        id
      }
    }
  }
`

const StyledField = styled(Field)(({ theme }) => ({
  height: "50px",
  width: "100%",
  fontSize: "14px",
  borderRadius: "50px",
  outline: "none",
  textAlign: "center",
  padding: "0px 18px",
  border: "solid 2px #000",
  [theme.breakpoints.up("sm")]: {
    width: "396px"
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "50px",
  fontSize: "14px",
  [theme.breakpoints.up("sm")]: {
    width: "396px"
  },
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

type Props = {
  method: PasswordMethod
  handleComplete: (number: string, email: string) => void
}

const PasswordForm: FC<Props> = (props) => {
  const [mutate] = useMutation<PasswordForm_CreatePasswordMutation>(mutation);
  const { method, handleComplete } = props;

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          method: method,
          email: values.email,
          number: values.number
        }
      },
      onCompleted: (res) => {
        handleComplete(values.number, values.email);
      }
    });
  }

  return (
    <>
      {
        method === "EMAIL" && <>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={emailSchema}
          >
            {(formik) => {
              return (
                <Form>
                  <StyledField type="text" name="email" placeholder="メールアドレスを入力" />
                  <div style={{ marginTop: "10px" }}>
                    <ErrorMessage name="email" component={StyledErrorMessage} />
                    <StyledButton type="submit">
                      {
                        formik.isSubmitting
                          ? <CircularProgress size={20} />
                          : "認証コードを送信"
                      }
                    </StyledButton>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      }

      {
        method === "NUMBER" && <>
          <Formik
            initialValues={{ number: "" }}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={numberSchema}
          >
            {(formik) => {
              return (
                <Form>
                  <StyledField type="text" name="number" placeholder="電話番号を入力" />
                  <div style={{ marginTop: "10px" }}>
                    <ErrorMessage name="number" component={StyledErrorMessage} />
                    <StyledButton type="submit">
                      {
                        formik.isSubmitting
                          ? <CircularProgress size={20} />
                          : "認証コードを送信"
                      }
                    </StyledButton>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      }
    </>
  )
}

export default PasswordForm;