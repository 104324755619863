/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PasswordMethod = "EMAIL" | "NUMBER" | "%future added value";
export type SessionVia = "APP" | "WEB" | "%future added value";
export type CreateSessionInput = {
    clientMutationId?: string | null | undefined;
    password: string;
    number?: string | null | undefined;
    email?: string | null | undefined;
    deviceId?: string | null | undefined;
    method: PasswordMethod;
    via: SessionVia;
};
export type SessionForm_CreateSessionMutationVariables = {
    input: CreateSessionInput;
};
export type SessionForm_CreateSessionMutationResponse = {
    readonly createSession: {
        readonly token: string;
    } | null;
};
export type SessionForm_CreateSessionMutation = {
    readonly response: SessionForm_CreateSessionMutationResponse;
    readonly variables: SessionForm_CreateSessionMutationVariables;
};



/*
mutation SessionForm_CreateSessionMutation(
  $input: CreateSessionInput!
) {
  createSession(input: $input) {
    token
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionForm_CreateSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSessionPayload",
        "kind": "LinkedField",
        "name": "createSession",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SessionForm_CreateSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSessionPayload",
        "kind": "LinkedField",
        "name": "createSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5518107bc1f37b6dce03b0697c836cf7",
    "id": null,
    "metadata": {},
    "name": "SessionForm_CreateSessionMutation",
    "operationKind": "mutation",
    "text": "mutation SessionForm_CreateSessionMutation(\n  $input: CreateSessionInput!\n) {\n  createSession(input: $input) {\n    token\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '66f13274b141bd7929541d6c57e7ade8';
export default node;
