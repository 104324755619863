import accounting from "accounting";

export default class Util {
  static numberToCurrency(number) {
    return `¥${accounting.formatNumber(number)}`;
  }

  static pad(num, size = 2) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
}
