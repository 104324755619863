import React, { FC, useState } from "react";
import { Button, Dialog, DialogContent, CircularProgress, styled } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import { TrackingNumberDialog_UpdateShopOrderShippingMutation } from "__generated__/api";

const carriers = [
  {
    label: '日本郵政',
    value: 'JAPAN_POST',
  },
  {
    label: 'ヤマト運輸',
    value: 'YAMATO',
  },
  {
    label: '佐川急便',
    value: 'SAGAWA',
  },
  {
    label: 'その他',
    value: 'OTHER',
  },
];

const mutation = gql`
  mutation TrackingNumberDialog_UpdateShopOrderShippingMutation($input: UpdateOrderShippingInput!) {
    updateOrderShipping(input: $input) {
      orderShipping {
        id
        trackingNumber
        carrier
        carrierName
      }
    }
  }
`

const SelectBox = styled(Field)({
  WebkitAppearance: "none",
  appearance: "none",
  height: "50px",
  width: "100%",
  borderRadius: "4px",
  outline: "none",
  padding: "0px 35px 0px 15px",
  border: "1px solid #ddd",
})

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  borderRadius: "4px",
  fontSize: "13px",
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px 15px",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

const StyledField = styled(Field)(({ theme }) => ({
  border: "1px solid #ddd",
  height: "50px",
  width: "100%",
  fontSize: "15px",
  borderRadius: "4px",
  outline: "none",
  padding: "0px 18px",
  textAlign: 'left',
}));


const StyledDialogContent = styled(DialogContent)({
  padding: "25px 25px !important",
  fontSize: "15px",
  color: "#000000",
  textAlign: "center",
  maxWidth: "420px",
  fontWeight: "bold"
});

type Props = {
  open: boolean;
  shippingId: string
  carrier?: string
  trackingNumber?: string
  handleClose: () => void;
}

export const TrackingNumberDialog: FC<Props> = (props) => {
  const { open, handleClose, shippingId } = props
  const [mutate] = useMutation<TrackingNumberDialog_UpdateShopOrderShippingMutation>(mutation);

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          orderShippingId: shippingId,
          carrier: values.carrier,
          trackingNumber: values.trackingNumber,
        }
      },
      onCompleted: (res) => {
        handleClose()
      },
      onError: (error) => {
        console.log(error)
      }
    });

  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          minWidth: "330px"
        }
      }}
    >
      <StyledDialogContent>
        <Formik
          initialValues={{
            trackingNumber: props.trackingNumber ?? '',
            carrier: props.carrier ?? '',
          }}
          onSubmit={onSubmit}
        // validateOnBlur={false}
        // validateOnChange={false}
        // validationSchema={}
        >{(formik) => {
          return (
            <Form>
              <div style={{ textAlign: 'left' }}>
                <p>運送会社</p>
                <SelectBox as="select" onChange={(e) => {
                  formik.setFieldValue("carrier", e.target.value)
                }} value={formik.values.carrier}>
                  <option>選択してください</option>
                  {
                    carriers.map((c, i) => {
                      return (
                        <option value={c.value} key={i}>{c.label}</option>
                      )
                    })
                  }
                </SelectBox>
              </div>

              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <p>追跡番号</p>

                <StyledField type="text" name="trackingNumber" placeholder="1234-5678-9012" />
              </div>

              <StyledButton type="submit">
                {
                  formik.isSubmitting
                    ? <CircularProgress size={20} />
                    : "保存する"
                }
              </StyledButton>
            </Form>
          )
        }}

        </Formik>
      </StyledDialogContent>
    </Dialog>
  )

}

export default TrackingNumberDialog