import React, { FC, useState } from "react";
import OrderDetail, { OrderDetailFragment } from "./OrderDetail/index";
import { gql } from "@apollo/client";
import { OrderShowScreen_query } from "__generated__/api";

export const OrderShowScreenFragment = gql`
  fragment OrderShowScreen_query on Query {
    viewer {
      shop {
        order(id: $id) {
          ...OrderDetail_order
        }
      }
    }
  }
  ${OrderDetailFragment}
`

type Props = {
  query: OrderShowScreen_query
}

const OrderShowScreen: FC<Props> = (props) => {
  const { query } = props

  return (
    <>
      <div style={{ padding: "30px 0px 50px" }}>
        <div style={{ display: "inline-block", marginBottom: "10px" }}>
          <h3>オーダー詳細</h3>
        </div>

        <OrderDetail order={query.viewer.shop.order} />
      </div>
    </>

  )

}

export default OrderShowScreen;