import React, { FC, useState } from "react";
import { styled } from "@mui/material";
import PasswordForm from "components/LoginScreen/PasswordForm";
import SessionForm from "components/LoginScreen/SessionForm";
import { PasswordMethod } from "__relay_artifacts__/PasswordForm_CreatePasswordMutation.graphql";
import { SignInWithAppleButton } from './SignInWithAppleButton';

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const ServiceName = styled("h1")({
  letterSpacing: "5px",
  marginBottom: "0px"
});

const Description = styled("p")({
  fontSize: '14px',
  color: "#000",
  marginTop: "0px",
  marginBottom: '20px'
})

const EmailButton = styled("div")({
  width: "100%",
  height: "50px",
  borderRadius: "50px",
  fontSize: "14px",
  backgroundColor: "#fff",
  border: "solid #000 2px",
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: "15px",
  fontWeight: "bold"
})

type Props = {}
const LoginScreen: FC<Props> = () => {
  const [method, setMethod] = useState<PasswordMethod>(null)
  const [step, setStep] = useState(1);
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleComplete = (number: string, email: string) => {
    setStep(2);
    setNumber(number);
    setEmail(email);
  }

  return (
    <>
      <Wrapper>
        <div style={{ width: "100%", textAlign: 'center' }}>

          <img src={"/logo_app.png"} style={{ width: 80, height: 80, marginBottom: 10 }} />

          <Description>ドロップの販売者用管理画面です。<br />アプリと同じ方法でログインできます</Description>

          {
            method === "EMAIL" && <>
              {
                step === 1 && <PasswordForm method={method} handleComplete={handleComplete} />
              }

              {
                step === 2 && <SessionForm number={number} email={email} method={method} />
              }
              <p style={{ fontSize: '14px' }} onClick={() => {
                setMethod(null)
                setStep(1)
              }}>戻る</p>
            </>
          }

          {
            method === null && <>
              <EmailButton onClick={() => { setMethod("EMAIL") }}>メールアドレスでログイン</EmailButton>
              <SignInWithAppleButton />
            </>
          }


        </div>
      </Wrapper>
    </>
  )
}

export default LoginScreen
