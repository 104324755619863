import React, { FC } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type Props = {
}

const HomeScreen: FC<Props> = (props) => {
  return (
    <>
      <div style={{ padding: "30px 0px 30px 0px" }}>
        {/* <Link to="/items" style={{ padding: "20px 15px 20px 15px", display: 'flex', alignItems: "center", justifyContent: 'space-between', backgroundColor: "#fff", borderRadius: "4px", marginBottom: "15px" }}>
          <p style={{ margin: "0px" }}>アイテムリスト</p>
          <KeyboardArrowRightIcon />
        </Link> */}

        <Link to={`/orders`} style={{ padding: "20px 15px 20px 15px", display: 'flex', alignItems: "center", justifyContent: 'space-between', backgroundColor: "#fff", borderRadius: "4px", marginBottom: "15px" }}>
          <p style={{ margin: "0px" }}>オーダーリスト</p>
          <KeyboardArrowRightIcon />
        </Link>

        {/* <div style={{ padding: "20px 15px 20px 15px", display: 'flex', alignItems: "center", justifyContent: 'space-between', backgroundColor: "#fff", borderRadius: "4px", marginBottom: "15px" }}>
          <p style={{ margin: "0px" }}>ライブリスト</p>
          <KeyboardArrowRightIcon />
        </div> */}
      </div>
    </>
  )
}

export default HomeScreen;
