import React, { FC } from "react";
import { gql } from "@apollo/client";
import { styled, List  } from "@mui/material";
import OrderPaymentListItem, { OrderPaymentListItemFragment } from "./OrderPaymentListItem";
import { OrderPaymentList_order } from "__generated__/api";

export const OrderPaymentListFragment = gql`
  fragment OrderPaymentList_order on Order {
    payments {
      edges {
        node {
          ...OrderPaymentListItem_payment
        }
      }
    }
  }
  ${OrderPaymentListItemFragment}
`

type Props = {
  order: OrderPaymentList_order
}

const HeaderRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "15px 0px",
  borderBottom: "solid 1px #244d8a1a",
});

const Status = styled("span")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  minWidth: "120px",
  width: "120px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px"
  },
}));

const AuctionName = styled("span")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  display: "flex",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px"
  },
}));

const Username = styled("span")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  display: "flex",
  flex: "1",
  [theme.breakpoints.down("sm")]: {
    flex: "none"
  },
}));

const Price = styled("span")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
}));

const FlexFirst = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 200px)",
  [theme.breakpoints.up("md")]: {
    width: "calc(100% - 500px)",
  },
}));

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "180px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

const OrderPaymentList: FC<Props> = (props) => {
  const order = props.order

  return (
    <>
      <HeaderRow>
        <FlexFirst>
          <Status>ステータス</Status>
          <AuctionName>オークション名</AuctionName>
        </FlexFirst>

        <FlexEnd>
          <Username>ユーザー名</Username>
          <Price>落札金額</Price>
        </FlexEnd>
      </HeaderRow>

      <List style={{padding: "0px"}}>
        {
          order.payments.edges.map((e, i) => {
            const payment = e.node
            return (<OrderPaymentListItem payment={payment} key={i} />)
          })
        }
      </List>
    </>
  )
}

export default OrderPaymentList;