import React, { FC } from "react";
import { Button, Dialog, DialogContent, DialogActions, styled } from "@mui/material";

const StyledDialogContent = styled(DialogContent)({
  padding: "50px !important",
  fontSize: "15px",
  color: "#000000",
  textAlign: "center",
  maxWidth: "350px",
  fontWeight: "bold"
});

const StyledDialogActions = styled(DialogActions)({
  borderTop: "1px solid #ebebeb",
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: "0px"
});

const StyledButton = styled(Button)({
  fontSize: '13px',
  width: "49%",
  height: "60px",
  fontWeight: "bold",
});

const StyledBorder = styled("div")({
  backgroundColor: "#ebebeb",
  height: "60px",
  width: "1px",
})

type Props = {
  message: string;
  open: boolean,
  doYes: () => void;
  doNo: () => void;
  yesText: string,
  noText: string
}

export const ConfirmDialog: FC<Props> = (props) => {
  const { message, open, doYes, doNo, yesText, noText } = props;

  return (
    <Dialog
      onClose={doNo}
      open={open}
      PaperProps={{
        style: {
          minWidth: "330px"
        }
      }}
    >
      <StyledDialogContent>
        {message}
      </StyledDialogContent>

      <StyledDialogActions disableSpacing={true}>
        <StyledButton onClick={doNo}>{noText}</StyledButton>
        <StyledBorder />
        <StyledButton onClick={doYes}>{yesText}</StyledButton>
      </StyledDialogActions>
    </Dialog>
  )

}

export default ConfirmDialog;