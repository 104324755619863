import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import OrdersScreen, { OrdersScreenFragment } from "components/OrdersScreen"
import { useQuery, gql } from '@apollo/client';
import { OrdersQuery } from "__generated__/api";


const query = gql`
  query OrdersQuery {
    ...OrdersScreen_query
  }
  ${OrdersScreenFragment}
`

type Props = {}
const Home: FC<Props> = () => {
  const { loading, error, data } = useQuery<OrdersQuery>(query, {
    fetchPolicy: "cache-and-network",
  });

  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <OrdersScreen query={data} />
      </Container>
    </>
  )
}

export default Home;