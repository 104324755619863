import React, { FC } from "react";
import { useUser } from "hooks/UserContext";
import { Route, Redirect, RouteProps } from "react-router-dom";
import Login from "pages/Login";

type Props = {
  redirectComponent?: string
} & RouteProps;

const PrivateRoute: FC<Props> = (props) => {
  const { user, isUserLoading } = useUser();
  const { component: Component, redirectComponent, ...rest } = props;

  if (isUserLoading) return <></>;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...props} />
        }


        if (redirectComponent) {
          // TODO: Props として取得するように修る
          switch (redirectComponent) {
            case "Login":
              return <Login {...props} />
          }
        }

        return <Redirect to={{ pathname: "/" }} />;
      }}
    />
  );
}

export default PrivateRoute;