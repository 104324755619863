import React, { FC, useState } from "react";
import { useQuery, gql } from '@apollo/client';
import OrderList, { OrderListFragment } from "./OrderList";
import { ClickPostCsvDownloadForm } from 'components/Common/ClickPostCsvDownloadButton'
import { OrdersScreen_query } from "__generated__/api";
import { styled, Button, Dialog, DialogContent } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { SagawaCsvDownloadForm } from "components/Common/SagawaCsvDownloadButton";

export const OrdersScreenFragment = gql`
  fragment OrdersScreen_query on Query {
    viewer {
      id

      shop {
        id

        ...OrderList_shop
      }
    }
  }
  ${OrderListFragment}
`

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "30px",
  textAlign: "center",
}));

const StyledButton = styled(Button)({
  backgroundColor: "#fafafa",
  color: "#000",
  fontSize: '12px',
  height: '50px',
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#fafafa",
  },
  width: "100%"
});

type Props = {
  query: OrdersScreen_query
}

const OrdersScreen: FC<Props> = (props) => {
  const { query } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenUploadDialog, setIsOpenUploadDialog] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleCloseUploadDialog = () => {
    setIsOpenUploadDialog(false)
  }

  const handleClick = () => {
    setIsOpenUploadDialog(true);
    setIsOpen(false);
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <StyledDialogContent>
          <h4 style={{ textAlign: "left", margin: "0px 0px 20px 0px" }}>CSV一括登録</h4>

          <div style={{ marginBottom: 12 }}>
            <ClickPostCsvDownloadForm />
            <SagawaCsvDownloadForm style={{marginTop: 20}} />
          </div>
        </StyledDialogContent>
      </Dialog>

      <div style={{ width: '100%', margin: "30px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h3>オーダー一覧</h3>

        <div onClick={() => { setIsOpen(true) }} style={{ cursor: "pointer" }}>
          <Settings style={{ fontSize: "25px", color: "#000e2b" }} />
        </div>
      </div>

      <div style={{ paddingBottom: "50px" }}>
        <OrderList shop={query.viewer.shop} />
      </div>
    </>
  )
}

export default OrdersScreen;
