import React, { FC } from "react";
import { Switch } from "react-router-dom"
import PrivateRouter from "components/PrivateRoute";
import Home from "pages/Home";
import Orders from "pages/Orders";
import OrderShow from "pages/OrderShow";

type Props = {}
const Router: FC<Props> = (props) => {
  return (
    <Switch>
      <PrivateRouter exact path="/" component={Home} redirectComponent={"Login"} />
      <PrivateRouter exact path="/orders" component={Orders} />
      <PrivateRouter exact path="/orders/:id" component={OrderShow} />
    </Switch>
  )
}

export default Router;
