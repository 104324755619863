import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { SessionForm_CreateSessionMutation, PasswordMethod } from "__relay_artifacts__/SessionForm_CreateSessionMutation.graphql";
import { Button, styled, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import StyledErrorMessage from "components/Common/StyledErrorMessage";

const schema = Yup.object({
  password: Yup.string().required("認証コードを入力してください")
});

const mutation = graphql`
  mutation SessionForm_CreateSessionMutation($input: CreateSessionInput!) {
    createSession(input: $input) {
      token
    }
  }
`

const StyledField = styled(Field)(({ theme }) => ({
  height: "50px",
  width: "100%",
  fontSize: "14px",
  borderRadius: "50px",
  outline: "none",
  textAlign: "center",
  padding: "0px 18px",
  border: "solid 2px #000",
  [theme.breakpoints.up("sm")]: {
    width: "396px"
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "50px",
  fontSize: "14px",
  [theme.breakpoints.up("sm")]: {
    width: "396px"
  },
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

type Props = {
  number: string
  email: string
  method: PasswordMethod
}

const SessionForm: FC<Props> = (props) => {
  const { number, email, method } = props;
  const [mutate] = useMutation<SessionForm_CreateSessionMutation>(mutation);

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          via: "WEB",
          method: method,
          number: number,
          email: email,
          password: values.password
        }
      },
      onCompleted: (res) => {
        localStorage.setItem("token", res.createSession.token);
        window.location.href = "/";
      }
    });
  }

  return (
    <>
      <Formik
        initialValues={{ password: "" }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formik) => {
          return (
            <Form>
              <StyledField type="text" name="password" placeholder="000000" pattern="[0-9]*" />
              <div style={{ marginTop: "10px" }}>
                <ErrorMessage name="password" component={StyledErrorMessage} />
                <StyledButton type="submit">
                  {
                    formik.isSubmitting
                      ? <CircularProgress size={20} />
                      : "ログイン"
                  }
                </StyledButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default SessionForm;