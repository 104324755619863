import React, { FC, useState } from "react";
import { useQuery, gql } from '@apollo/client';
import { Link } from "react-router-dom";
import { styled, ListItem  } from "@mui/material";
import Util from "lib/Util";
import format from "date-fns/format";
import ja from "date-fns/locale/ja";
import ShippingStatusLabel, { ShippingStatusLabelFragment } from "components/Common/ShippingStatusLabel"
import { OrderListItem_order } from "__generated__/api";

export const OrderListItemFragment = gql`
  fragment OrderListItem_order on Order {
    id
    amount
    createdAt

    user {
      id
      username
    }

    address {
      id
      fullName
    }

    ...ShippingStatusLabel_order
  }
  ${ShippingStatusLabelFragment}
`

const StyledListItem = styled(ListItem)({
  borderRadius: "8px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
  padding: "16px 16px",
});

const FlexFirst = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 500px)"
})

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "190px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

const Status = styled("span")(({ theme }) => ({
  width: "110px",
  minWidth: "110px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

const Buyer = styled("span")(({ theme }) => ({
  width: "110px",
  minWidth: "110px",
  fontSize: "13px",
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

const Price = styled("span")(({ theme }) => ({
  display: "flex",
  flex: "1",
  fontSize: "13px",
  [theme.breakpoints.down("sm")]: {
    flex: "none",
  },
}));

const CreatedAt = styled("span")(({ theme }) => ({
  fontSize: "13px",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
}));

type Props = {
  order: OrderListItem_order
}

const OrderListItem: FC<Props> = (props) => {
  const { order } = props

  return (
    <Link to={`/orders/${order.id}`}>
      <StyledListItem button={false as any}>
        <FlexFirst>
          <Status>
            <ShippingStatusLabel order={order} />
          </Status>
          <Buyer>
            {`${order.address.fullName} / ${order.user.username}`}
          </Buyer>
        </FlexFirst>

        <FlexEnd>
          <Price>{Util.numberToCurrency(order.amount)}</Price>
          <CreatedAt>{format(new Date(order.createdAt), "Y/M/d HH:mm", { locale: ja })}</CreatedAt>
        </FlexEnd>
      </StyledListItem>
    </Link>
  )

}

export default OrderListItem;