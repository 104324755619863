import React, { FC, useEffect, useState } from "react";
import { gql, useLazyQuery } from '@apollo/client';
import { styled, List  } from "@mui/material";
import OrderListItem, { OrderListItemFragment } from "./OrderListItem";
import Pagination from '@material-ui/lab/Pagination';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { OrderList_shop, OrderListQuery } from "__generated__/api";

const query = gql`
  query OrderListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    ) {
    viewer {
      id

      shop {
        id

        orders(
          first: $first
          after: $after
          last: $last
          before: $before
        ) {
          edges {
            node {
              ...OrderListItem_order
            }
          }

          pageInfo {
            endCursor
            hasNextPage
            startCursor
            hasPreviousPage
          }

          totalCount
        }
      }
    }
  }
  ${OrderListItemFragment}
`

export const OrderListFragment = gql`
  fragment OrderList_shop on Shop {
    id
  }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      justifyContent: "flex-end"
    }
  })
)

const HeaderRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
  padding: "0px 15px"
});

const StatusHeader = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px"
  },
  [theme.breakpoints.up("md")]: {
    width: "110px",
  },
}));

const BuyerHeader = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px"
  },
  [theme.breakpoints.up("md")]: {
    width: "110px",
  },
}));

const PriceHeader = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  display: "flex",
  flex: "1",
  [theme.breakpoints.down("sm")]: {
    flex: "none"
  },
}));

const LiveDate = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
}));

const FlexFirst = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 200px)",
  [theme.breakpoints.up("md")]: {
    width: "calc(100% - 500px)",
  },
}));

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "190px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

type Props = {
  shop: OrderList_shop
}

const OrderList: FC<Props> = (props) => {
  const classes = useStyles({});
  const [page, setPage] = useState(1)
  const [variables, setVariables] = useState({ first: 10 })

  const [fetchQuery, { data, loading }] = useLazyQuery<OrderListQuery>(query, {
    fetchPolicy: "network-only",
    variables,
    onCompleted: (data) => {
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    console.log(variables)
    fetchQuery({
      variables
    })
  }, [variables])

  if (!data || loading) return <></>

  const orders = data.viewer.shop.orders
  const totalPages = Math.ceil(orders.totalCount / 10);

  const handleChangePage = (event, newPage) => {
    let directedPage;

    if (page - newPage < 0) {
      // next page
      directedPage = { //　次のページを取得するための情報
        after: orders
          ? orders.pageInfo.endCursor
          : undefined,
        first: 10,
      };
    } else {
      // previous page
      directedPage = { // 前のページを取得するための情報
        before: orders
          ? orders.pageInfo.startCursor
          : undefined,
        last: 10,
      };

    }

    setPage(newPage)
    setVariables(directedPage)
  };


  return (
    <>
      <HeaderRow>
        <FlexFirst>
          <StatusHeader>ステータス</StatusHeader>
          <BuyerHeader>購入者</BuyerHeader>
        </FlexFirst>

        <FlexEnd>
          <PriceHeader>金額</PriceHeader>
          <LiveDate>作成日時</LiveDate>
        </FlexEnd>
      </HeaderRow>

      <List style={{ marginBottom: '20px' }}>
        {
          data.viewer.shop.orders.edges.map((edge, i) => {
            const order = edge!.node!
            return (
              <OrderListItem order={order} key={i} />
            )
          })
        }
      </List>

      <Pagination count={totalPages} page={page} classes={{ ul: classes.pagination }} onChange={handleChangePage} />
    </>
  )
}

export default OrderList;