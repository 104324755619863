import React, { FC, useState, useCallback } from "react";
import { styled, AppBar, Toolbar, Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { ArrowBack, Menu, Close } from "@mui/icons-material";
import CustomMenu from "components/Common/CustomMenu";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#fff",
  boxShadow: "none",
  borderBottom: "1px solid #ebebeb",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledMenuIcon = styled(Menu)({
  color: "#fff"
});

const StyledLogo = styled("span")({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#000000de",
  letterSpacing: "1.4px",
  fontFamily: 'sohne, "Helvetica Neue", Helvetica, Arial, sans-serif'
});

const StyledContainer = styled(Container)({
  marginTop: "0px !important",
  marginBottom: "0px !important"
});

const HeightAjuster = styled("div")(({ theme }) => ({
  height: "56px",
  [theme.breakpoints.up("sm")]: {
    height: "64px",
  },
}));

const StyledBackIconButton = styled(IconButton)({
  padding: "7px"
})

const StyledIconButton = styled(IconButton)({
  backgroundColor: "#000",
  padding: "5px",
  "&:hover": {
    backgroundColor: "#000",
  }
});

const StyledCloseIcon = styled(Close)({
  color: "#fff"
});

type Props = {
  backPath?: string
}

const PageAppBar: FC<Props> = (props) => {
  const { backPath } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleBack = useCallback(() => {
    history.push(backPath);
  }, [backPath]);

  return (
    <>
      <StyledAppBar position="fixed">
        <StyledContainer maxWidth="md">
          <StyledToolbar disableGutters={true}>

            {
              backPath
                ?
                <StyledBackIconButton onClick={handleBack} >
                  <ArrowBack />
                </StyledBackIconButton>
                :
                <Link to="/" style={{ textDecoration: 'none', height: "35px" }}>
                  <img src={"/logo_app.png"} style={{ width: 35, height: 35 }} />
                </Link>
            }

            <p style={{ fontSize: 14, color: "#000" }}>販売者管理画面</p>

            {/* <StyledIconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true">
              {anchorEl ? <StyledCloseIcon /> : <StyledMenuIcon />}
            </StyledIconButton> */}

            {/* <CustomMenu handleClose={handleClose} anchorEl={anchorEl} /> */}

          </StyledToolbar>
        </StyledContainer>
      </StyledAppBar>

      <HeightAjuster />
    </>
  )
}

export default PageAppBar;
