import React, { FC, useState } from "react";
import { gql } from "@apollo/client";
import { styled, ListItem } from "@mui/material";
import Util from "lib/Util";
import PaymentStatusLabel, { PaymentStatusLabelFragment } from "components/Common/PaymentStatusLabel";
import { OrderPaymentListItem_payment } from "__generated__/api";

export const OrderPaymentListItemFragment = gql`
  fragment OrderPaymentListItem_payment on OrderPayment {
    id
    amount
    itemName
    itemPrice

    shipping {
      id
      fee
    }

    customer {
      id
      username
    }

    ...PaymentStatusLabel_payment
  }
  ${PaymentStatusLabelFragment}
`

const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "30px 0px",
  borderBottom: "solid 1px #244d8a1a",
});

const FlexFirst = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 500px)"
})

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "180px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

const Status = styled("span")(({ theme }) => ({
  width: "120px",
  minWidth: "120px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

const Name = styled("span")(({ theme }) => ({
  display: "flex",
  flex: 1,
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

const Username = styled("span")(({ theme }) => ({
  fontSize: "14px",
  display: "flex",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flex: "none"
  },
}));

const Price = styled("span")(({ theme }) => ({
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
}));

const PriceSP = styled("span")(({ theme }) => ({
  display: "block",
  marginTop: "10px",
  fontSize: "14px",
  [theme.breakpoints.up("sm")]: {
    display: "none"
  },
}));


type Props = {
  payment: OrderPaymentListItem_payment
}

const OrderPaymentListItem: FC<Props> = (props) => {
  const payment = props.payment

  return (
    <StyledListItem button={false as any}>
      <FlexFirst>
        <Status><PaymentStatusLabel payment={payment} /></Status>
        <div>
          <Name>{payment.itemName}</Name>
          <PriceSP>{Util.numberToCurrency(payment.itemPrice)}</PriceSP>
        </div>
      </FlexFirst>

      <FlexEnd>
        <Username>{payment.customer.username}</Username>
        <Price>{Util.numberToCurrency(payment.itemPrice)}</Price>
      </FlexEnd>
    </StyledListItem>
  )

}

export default OrderPaymentListItem;