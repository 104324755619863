import { Form, Formik } from 'formik'
import React, { CSSProperties, FC } from 'react'
import { styled, Button } from "@mui/material";

type Props = {
  style?: CSSProperties
}

const StyledButton = styled(Button)({
  backgroundColor: "#fafafa !important",
  color: "#000",
  fontSize: '12px',
  height: '50px',
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#fafafa",
  },
  width: "100%"
});


export const SagawaCsvDownloadForm: FC<Props> = ({ style }) => {
  const onSubmit = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/download_all_sagawa_csv`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
    const disposition = response.headers.get('content-disposition')
    const filename = disposition.replace(/.*filename=\"/, '').replace(/\".*/, '')
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }

  return (
    <div style={style}>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {
          ({ errors }) => {
            return (
              <Form>
                <StyledButton type="submit">
                  未発送分CSVダウンロード(佐川急便)
                </StyledButton>
              </Form>
            )
          }
        }
      </Formik>
    </div>
  )
}
