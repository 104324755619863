import AppleSignin from 'react-apple-signin-auth';
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from 'relay-hooks';
import { SignInWithAppleButton_CreateAppleSessionMutation } from '__relay_artifacts__/SignInWithAppleButton_CreateAppleSessionMutation.graphql';

const mutation = graphql`
  mutation SignInWithAppleButton_CreateAppleSessionMutation($input: CreateAppleSessionInput!) {
    createAppleSession(input: $input) {
      token
    }
  }
`

export const SignInWithAppleButton = () => {
  const [mutate] = useMutation<SignInWithAppleButton_CreateAppleSessionMutation>(mutation);

  const handleSuccess = async (res) => {
    await mutate({
      variables: {
        input: {
          via: "WEB",
          token: res.authorization.id_token,
        }
      },
      onCompleted: (res) => {
        localStorage.setItem("token", res.createAppleSession.token);
        window.location.href = "/";
      },
      onError: (error) => {
        console.log(error)
      }
    });
  }

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        nonce: 'nonce',
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="light"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      // style={
      //   {
      //     borderRadius: "50px",
      //     width: "100%",
      //     height: "50px",
      //     fontWeight: "bold",
      //     borderWidth: "2px",
      //     fontSize: "14px"
      //   }
      // }
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Apple でサインイン"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      // onSuccess={(response) => console.log(response)} // default = undefined
      onSuccess={handleSuccess}
      /** Called upon signin error */
      onError={(error) => console.error(error)} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      // iconProp={{ style: { marginTop: '10px' } }} // default = undefined
    /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
    // render={(props) => <button {...props}>My Custom Button</button>}
    />

  );
};
