/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserContextQueryVariables = {};
export type UserContextQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly username: string;
    } | null;
};
export type UserContextQuery = {
    readonly response: UserContextQueryResponse;
    readonly variables: UserContextQueryVariables;
};



/*
query UserContextQuery {
  viewer {
    id
    username
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContextQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserContextQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8c7a38d2254243ce72735b189d80e2ef",
    "id": null,
    "metadata": {},
    "name": "UserContextQuery",
    "operationKind": "query",
    "text": "query UserContextQuery {\n  viewer {\n    id\n    username\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd2e0e365bf13bac90e5f4ecc2b55f462';
export default node;
