import React, { FC } from "react";
import { Container } from "@mui/material";
import LoginScreen from "components/LoginScreen";

type Props = {}
const Home: FC<Props> = () => {
  return (
    <Container maxWidth="xs" style={{ height: "100%" }}>
      <LoginScreen />
    </Container>
  )
}

export default Home;