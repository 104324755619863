import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import HomeScreen from "components/HomeScreen";

type Props = {}
const Home: FC<Props> = () => {

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <HomeScreen />
      </Container>
    </>
  )
}

export default Home;