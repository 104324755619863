import { createTheme } from '@mui/material/styles';

const fontFamily = '"Montserrat", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "a-otf-midashi-go-mb31-pr6n", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif';

let theme = createTheme()
theme = createTheme(theme, {
  typography: {
    fontFamily
  },
  palette: {
    primary: {
      main: "#000000",
    },
    background: {
      default: "#fafafa"
    }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: "none",
          borderRadius: "12px",
          minWidth: "95%",
          [theme.breakpoints.up("sm")]: {
            minWidth: "400px",
            maxWidth: "400px !important",
          },
        },
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "#fff !important",
        },
      }
    }
  }
});

export default theme;