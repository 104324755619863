import React, { useEffect, createContext, useContext, FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { handleFetch } from "lib/handleFetch";
import { UserContextQueryResponse } from "__relay_artifacts__/UserContextQuery.graphql";

const query = graphql`
  query UserContextQuery {
    viewer {
      id
      username
    }
  }
`

type User = {
  id: string
  username: string
}

type ContextProps = Partial<{
  user?: User
  setCurrentUser: (user: User) => void
  isUserLoading: boolean
}>

const UserContext = createContext<ContextProps>({});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isUserLoading, setIsUserLoading] = useState(true);
  const setCurrentUser = (user?: User) => { setUser(user); }

  const pathname = window.location.pathname;
  const isPublicPage = [].includes(pathname);

  useEffect(() => {
    if (isPublicPage) {
      setIsUserLoading(true);
      return;
    }

    const f = async () => {
      const res = (await handleFetch(query, {})) as UserContextQueryResponse;

      if (res.viewer) {
        const user = {
          id: res.viewer.id,
          username: res.viewer.username
        };

        setUser(user);
      }

      setIsUserLoading(false);
    }

    f();
  }, []);

  const value = { user, setCurrentUser, isUserLoading };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContext;

export const useUser = () => useContext(UserContext);